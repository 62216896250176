import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'

import { Link } from 'gatsby'

import firebase from 'firebase/app'
import { getAuth } from 'firebase/auth'

import CircularProgress from '@material-ui/core/CircularProgress'

type SignOutProps = {
  firebase: firebase.FirebaseApp
}

const SignOut: FunctionComponent<SignOutProps> = ({
  firebase: firebaseApp,
}) => {
  const [signOutSuccessful, setSignOutSuccessful] = React.useState<
    boolean | undefined
  >(undefined)
  React.useEffect(() => {
    const asyncEffect = async (user: unknown) => {
      if (!user) {
        setSignOutSuccessful(true)
        return
      }

      await getAuth(firebaseApp).signOut()
      setSignOutSuccessful(true)
    }

    // Call effect once auth is resolved.
    const unsubscribeOnAuthStateChanged = getAuth(
      firebaseApp,
    ).onAuthStateChanged((user) => {
      void asyncEffect(user)
    })

    return () => {
      unsubscribeOnAuthStateChanged()
    }
  })

  return (
    <>
      <Helmet title="Signing Out of HostBurro" />
      <h1>Signing Out</h1>
      {signOutSuccessful ? (
        <>
          You have successfully been signed out of <Link to="/">HostBurro</Link>
          .
        </>
      ) : (
        <CircularProgress size={24} />
      )}
    </>
  )
}

export default SignOut
